//PRODUCTS
export const GET_PRODUCTS="GET_PRODUCTS"
export const GET_MOBILES="GET_MOBILES"
export const FILTERD_PRODUCTS = "FILTERD_PRODUCTS"
export const GET_ONE_PRODUCTS = "GET_ONE_PRODUCTS"
export const SET_FILTER = "SET_FILTER"

// BANNER
export const GET_BANNER="GET_BANNER"
export const GET_SLIDER_BANNER="GET_SLIDER_BANNER"
export const GET_TESTIOMONIAL_BANNER="GET_TESTIOMONIAL_BANNER"

//FAQ
export const GET_FAQ="GET_FAQ"

//CART
export const GET_CART="GET_CART"
export const ADD_CART="ADD_CART"
export const DELETE_CART="DELETE_CART"

//COUNTRY
export const GET_COUNTRIES="GET_COUNTRIES"

//STATES
export const GET_STATES="GET_STATES"

//CITIES
export const GET_CITIES="GET_CITIES"

//COUPONS
export const GET_COUPONS="GET_COUPONS"

//SETTINGS
export const GET_SETTINGS="GET_SETTINGS"

//BRANDS
export const GET_BRANDS="GET_BRANDS"

//MODELS
export const GET_MODELS="GET_MODELS"

//ORDERS
export const GET_ORDERS="GET_ORDERS"
export const ADD_ORDERS="ADD_ORDERS"
