import { FILTERD_PRODUCTS, GET_MOBILES, GET_ONE_PRODUCTS, GET_PRODUCTS, SET_FILTER } from "../types";

const initialState = {
  list: [],
  mobcat: [],
  single: [],
  filter: {
    // color: '',
    color: [],
    storage: [],
    ram: [],
    // brandId: '',
    brands: [],
    modelId: [],
    category: [],
    discount: '',
    minPrice: '',
    maxPrice: '',
    page: 1,
    limit: 15
  },
  filteredProducts: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        list: payload,
        loading: false
      };
    
    case GET_MOBILES:
      return {
        ...state,
        mobcat: payload,
        loading: false
      };
    
    case GET_ONE_PRODUCTS:
      console.log("payload in redux :", payload);
      
      return {
        ...state,
        single: payload,
        loading: true
      };

    case FILTERD_PRODUCTS:
      return {
        ...state,
        filteredProducts: payload,
        loading: false
      };
    
    case SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.filterType]: payload.value
        }
      };
    // case SET_FILTER:
    //   return {
    //     ...state,
    //     filter: [{
    //       ...state.filter,
    //       [payload.filterType]: payload.value
    //     }]
    //   };

    default:
      return state;
  }
};

export default reducer;
