import config from "./config";
import axios from "axios";
import {message} from "antd";
const baseURL =config.API_URL;

const axiosInstance = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        if (["user/login", "ban/get", "prod/get", "sett/get", "brand/get", "model/get"].includes(config.url)) {
            // config.headers["authorization"] = "essential";
            // config.headers["x-access-token"] = "essential";
        } else if (["refresh/access"].includes(config.url)) {
            config.headers["Authorization"] = "refresh";
            // config.headers["x-access-token"] = "refresh";
        } else {
            const accessToken = localStorage.getItem('auth');            
            if (accessToken) {
                config.headers['Authorization'] = `${accessToken}`;
                // config.headers['x-access-token'] = `${accessToken}`            
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response.status === 401 &&
            originalRequest.url === `refresh/access`
        ) {
            localStorage.removeItem("auth");
            window.location.href = '/login-register';
            // window.location.reload();
            return Promise.reject(error);
        }

        // Access Token was expired
        console.log({LL: originalRequest.url});
        console.log("error.response",error.response);
        message.error(error.response.data);
        if (
            error.response.status === 401 &&
            originalRequest.url !== `/login-register` &&
            !originalRequest._retry 
        ) {
            originalRequest._retry = true;
            try {
                const refreshToken = JSON.parse(localStorage.getItem('auth'))?.refresh_token;
                const res = await axiosInstance.post(`refresh/access`, {
                    token: refreshToken,
                });

                let auth = JSON.parse(localStorage.getItem('auth'));
                auth = {...auth, access_token: res.data.token};
                localStorage.setItem("auth", JSON.stringify(auth));
                axiosInstance.defaults.headers.common["Authorization"] = res.data.token;
                // axiosInstance.defaults.headers.common["x-access-token"] = res.data.access_token;

                return axiosInstance(originalRequest);
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;