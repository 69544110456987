// import { createStore, applyMiddleware, compose } from "redux";
// import thunk from 'redux-thunk';
// import createSagaMiddleware from "redux-saga";
// import rootReducer from "./reducers";
// import rootSaga from "./sagas"; // Ensure this file exists

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [thunk, sagaMiddleware];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(...middlewares))
// );

// sagaMiddleware.run(rootSaga);

// export default store; // Export the store instance as default



// sagaMiddleware.run(rootSaga);


// import { createStore, applyMiddleware, compose } from "redux";
// import createSagaMiddleware from "redux-saga";
// import {thunk} from 'redux-thunk';
// import rootReducer from "./reducers";
// import rootSaga from "./sagas";

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [thunk];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export function configureStore(initialState) {

//   const store = createStore(
//     rootReducer,
//       initialState,
//       composeEnhancers(
//           applyMiddleware(...middlewares)
//       ),
//   );
//   //sagaMiddleware.run(rootSaga);
//   return store;
// }


import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// Import reducers
// import calendar from "./calendar/reducer";
// import forgetPassword from "./auth/forgetpwd/reducer";
// import login from "./auth/login/reducer";
// import profile from "./auth/profile/reducer";
// import account from "./auth/register/reducer";
// import item from "./reducers/item";
// import salesman from "./reducers/salesman";
// import customers from "./reducers/customers";
import products from "./reducers/products.js";
// import brand from "./reducers/brand";
// import model from "./reducers/model";
// import series from "./reducers/series";
// import productorder from "./reducers/productorders";
import country from "./reducers/country";
import state from "./reducers/state";
import city from "./reducers/city";
import settings from "./reducers/settings";
import brands from "./reducers/brands.js";
import models from "./reducers/models.js";
import banner from "./reducers/banner";
import faq from "./reducers/faq.js";
import coupons from "./reducers/coupons.js";
import order from "./reducers/order.js";
import fastcart from "./reducers/cart.js";
import userSlice from "./Auth/user-slice";
import citystatecountrySlice from './slices/city-state-country';
import wishlistReducer from "./slices/wishlist-slice";
import compareReducer from "./slices/compare-slice";
import cartReducer from "./slices/cart-slice";
// import productReducer from './slices/product-slice';

// Persist config
const persistConfig = {
    key: "flone",
    version: 1.1,
    storage,
};

// Combine reducers
const rootReducer = combineReducers({
    // Layout,
    // calendar,
    // forgetPassword,
    // login,
    // profile,
    products,
    settings,
    brands,
    models,
    // product: productReducer,
    // brand,
    // model,
    // series,
    // coupons,
    // notify,
    // productorder,
    country,
    state,
    city,
    fastcart,
    coupons,
    order,
    // settings,
    wishlist: wishlistReducer,
    compare: compareReducer,
    banner,
    faq,
    cart: cartReducer,
    user: userSlice, // User slice for persistence
    citystatecountry: citystatecountrySlice
});

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

// Create persistor
export const persistor = persistStore(store);



// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import {
//     persistStore,
//     persistReducer,
//     FLUSH,
//     REHYDRATE,
//     PAUSE,
//     PERSIST,
//     PURGE,
//     REGISTER,
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import productReducer from './slices/product-slice';
// import currencyReducer from "./slices/currency-slice";
// import cartReducer from "./slices/cart-slice";
// import compareReducer from "./slices/compare-slice";
// import wishlistReducer from "./slices/wishlist-slice";
// import bannerSlice from "./slices/banner-slice";
// import userSlice from "./Auth/user-slice"
// import citystatecountrySlice from './slices/city-state-country';

// const persistConfig = {
//     key: "flone",
//     version: 1.1,
//     storage,
// };

// export const rootReducer = combineReducers({
//     // product: productReducer,
//     // currency: currencyReducer,
//     // cart: cartReducer,
//     compare: compareReducer,
//     // wishlist: wishlistReducer,
//     // banner: bannerSlice,
//     user : userSlice,
//     citystatecountrty : citystatecountrySlice
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware({
//             serializableCheck: {
//                 ignoredActions: [
//                     FLUSH,
//                     REHYDRATE,
//                     PAUSE,
//                     PERSIST,
//                     PURGE,
//                     REGISTER,
//                 ],
//             },
//         }),
// });

// export const persistor = persistStore(store);