import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { 
  Table, 
  Card, 
  Tag, 
  Modal, 
  Button, 
  Space, 
  Typography,
  Empty,
  Grid,
  List,
  Descriptions
} from 'antd';
import { 
  ShoppingCartOutlined, 
  EyeOutlined, 
  ShoppingOutlined 
} from '@ant-design/icons';
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { getOrders } from "../../store/actions/order";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const orderStatusColors = {
  0: 'gold',    // PENDING
  1: 'blue',    // IN-PROCESS
  2: 'red',     // CANCEL
  3: 'green',   // COMPLETE
};

const orderStatusMap = {
  0: 'PENDING',
  1: 'IN-PROCESS',
  2: 'CANCEL',
  3: 'COMPLETE',
};

const CheckOrders = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const screens = useBreakpoint();
  
  const isLoggedIn = useSelector((state) => state.user.user !== null);
  const orders = useSelector((state) => state.order.list);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!isLoggedIn) {
        navigate("/login-register");
        return;
      }
      await dispatch(getOrders());
    };
    fetchOrders();
  }, [dispatch, isLoggedIn, navigate]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalVisible(true);
  };

  // Mobile order item renderer
  const renderOrderItem = (item) => {
    const discountedPrice = item.disPer
      ? item.price - (item.price * item.disPer / 100)
      : item.price;

    return (
      <Card 
        size="small" 
        style={{ marginBottom: 8 }}
        bodyStyle={{ padding: '12px' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Link to={`${process.env.PUBLIC_URL}/product/${item._id}`}>
            <Text strong>{item.name}</Text>
          </Link>
          <Space split={<Text type="secondary" style={{ margin: '0 8px' }}>|</Text>}>
            <Text>Qty: {item.qty}</Text>
            <Text>
              ₹{discountedPrice}
              {item.disPer > 0 && (
                <Text type="secondary" style={{ marginLeft: 8, textDecoration: 'line-through' }}>
                  ₹{item.price}
                </Text>
              )}
            </Text>
          </Space>
          <Text>Subtotal: ₹{(discountedPrice * item.qty).toFixed(2)}</Text>
        </Space>
      </Card>
    );
  };

  // Mobile order renderer
  const renderMobileOrder = (order) => (
    <Card 
      key={order._id}
      style={{ marginBottom: 16 }}
      bodyStyle={{ padding: screens.xs ? 12 : 24 }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space split={<Text type="secondary" style={{ margin: '0 8px' }}>|</Text>}>
          <Text copyable>{order._id}</Text>
          <Tag color={orderStatusColors[order.orderStatus]}>
            {orderStatusMap[order.orderStatus]}
          </Tag>
        </Space>

        <Descriptions
          column={1}
          size="small"
          labelStyle={{ fontWeight: 'bold' }}
          contentStyle={{ textAlign: 'right' }}
        >
          <Descriptions.Item label="Total Amount">₹{order.totalAmt}</Descriptions.Item>
          <Descriptions.Item label="Advance Payment">₹{order.advAmt}</Descriptions.Item>
          <Descriptions.Item label="Balance">₹{order.totalAmt - order.advAmt}</Descriptions.Item>
        </Descriptions>

        <Button 
          type="link" 
          icon={<EyeOutlined />}
          onClick={() => handleImageClick(order.imageUrl)}
          style={{ padding: 0 }}
        >
          View Payment Proof
        </Button>

        <div style={{ marginTop: 8 }}>
          <Text strong>Order Items:</Text>
          {order.items.map((item, index) => (
            <div key={index} style={{ marginTop: 8 }}>
              {renderOrderItem(item)}
            </div>
          ))}
        </div>
      </Space>
    </Card>
  );

  // Desktop table columns
  const columns = [
    {
      title: 'Order ID',
      dataIndex: '_id',
      key: '_id',
      render: (id) => <Text copyable>{id}</Text>,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'orderStatus',
      key: 'status',
      render: (status) => (
        <Tag color={orderStatusColors[status]}>
          {orderStatusMap[status]}
        </Tag>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmt',
      key: 'totalAmt',
      render: (amount) => `₹${amount}`,
    },
    {
      title: 'Advance Payment',
      dataIndex: 'advAmt',
      key: 'advAmt',
      render: (amount) => `₹${amount}`,
      responsive: ['md'],
    },
    {
      title: 'Balance',
      key: 'balance',
      render: (_, record) => `₹${record.totalAmt - record.advAmt}`,
      responsive: ['md'],
    },
    {
      title: 'Payment Proof',
      key: 'proof',
      render: (_, record) => (
        <Button 
          type="link" 
          icon={<EyeOutlined />}
          onClick={() => handleImageClick(record.imageUrl)}
        >
          View
        </Button>
      ),
    },
  ];

  // Expandable row for desktop
  const expandedRowRender = (order) => {
    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
          <Link to={`${process.env.PUBLIC_URL}/product/${record._id}`}>
            {name}
          </Link>
        )
      },
      {
        title: 'Unit Price',
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => {
          const discountedPrice = record.disPer
            ? price - (price * record.disPer / 100)
            : price;
          return (
            <Text>
              ₹{discountedPrice}
              {record.disPer > 0 && (
                <Text type="secondary" style={{ marginLeft: 8, textDecoration: 'line-through' }}>
                  ₹{price}
                </Text>
              )}
            </Text>
          );
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: 'Subtotal',
        key: 'subtotal',
        render: (_, record) => {
          const discountedPrice = record.disPer
            ? record.price - (record.price * record.disPer / 100)
            : record.price;
          return `₹${(discountedPrice * record.qty).toFixed(2)}`;
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={order.items}
        pagination={false}
        style={{ backgroundColor: '#f5f5f5' }}
      />
    );
  };

  return (
    <Fragment>
      <SEO titleTemplate="CheckOrders" description="Check Orders of 09Mobile Gallery." />
      <LayoutOne headerTop="visible">
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Orders", path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        
        <div className="cart-main-area pt-90 pb-100">
          <div className="container">
            {orders && orders.length > 0 ? (
              <Fragment>
                <Card>
                  <Title level={3} style={{ marginBottom: 24 }}>Your ordered items</Title>
                  
                  {/* Responsive Layout Switch */}
                  {screens.md ? (
                    // Desktop View
                    <Table
                      columns={columns}
                      dataSource={orders}
                      expandable={{
                        expandedRowRender,
                        expandRowByClick: true,
                      }}
                      rowKey="_id"
                      scroll={{ x: true }}
                    />
                  ) : (
                    // Mobile View
                    <List
                      dataSource={orders}
                      renderItem={renderMobileOrder}
                      pagination={false}
                    />
                  )}

                  {/* <div style={{ 
                    textAlign: 'right', 
                    marginTop: 24,
                    padding: screens.xs ? '0 12px' : 0 
                  }}>
                    <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>
                      <Button type="primary" icon={<ShoppingOutlined />}>
                        Continue Shopping
                      </Button>
                    </Link>
                  </div> */}
                  <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>Continue Shopping</Link>
                      </div>
                    </div>
                  </div>
                </div>
                </Card>
              </Fragment>
            ) : (
              <Card>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <Space direction="vertical" align="center">
                      <Text>No items found in orders</Text>
                      <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>
                        <Button type="primary" icon={<ShoppingCartOutlined />}>
                          Shop Now
                        </Button>
                      </Link>
                    </Space>
                  }
                />
              </Card>
            )}
          </div>
        </div>

        <Modal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={screens.md ? 800 : '95%'}
          title="Payment Proof"
        >
          <img 
            src={selectedImage} 
            alt="Payment Proof" 
            style={{ width: '100%' }}
          />
        </Modal>
      </LayoutOne>
    </Fragment>
  );
};

export default CheckOrders;




// import { Fragment, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Modal } from 'antd'; // Import Modal from antd
// import SEO from "../../components/seo";
// import { getDiscountPrice } from "../../helpers/product";
// import LayoutOne from "../../layouts/LayoutOne";
// import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import { getOrders } from "../../store/actions/order";

// const orderStatusMap = {
//   0: 'PENDING',
//   1: 'IN-PROCESS',
//   2: 'CANCEL',
//   3: 'COMPLETE',
// };

// const CheckOrders = () => {
//   const [cartTotalPrice, setCartTotalPrice] = useState(0);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   let { pathname } = useLocation();
//   const isLoggedIn = useSelector((state) => state.user.user !== null);
//   const orders = useSelector((state) => state.order.list);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       if (!isLoggedIn) {
//         navigate("/login-register"); // Redirect to login if not logged in
//         return;
//       }
//       await dispatch(getOrders());
//     };
//     fetchOrders();
//   }, [dispatch, isLoggedIn, navigate]);

//   const handleImageClick = (imageUrl) => {
//     setSelectedImage(imageUrl);
//     setIsModalVisible(true);
//   };

//   const handleModalClose = () => {
//     setIsModalVisible(false);
//     setSelectedImage(null);
//   };

//   return (
//     <Fragment>
//       <SEO titleTemplate="CheckOrders" description="Check Orders of 09Mobile Gallery." />
//       <LayoutOne headerTop="visible">
//         <Breadcrumb
//           pages={[
//             { label: "Home", path: process.env.PUBLIC_URL + "/" },
//             { label: "Orders", path: process.env.PUBLIC_URL + pathname },
//           ]}
//         />
//         <div className="cart-main-area pt-90 pb-100">
//           <div className="container">
//             {orders && orders.length > 0 ? (
//               <Fragment>
//                 <h3 className="cart-page-title">Your ordered items</h3>
//                 <div className="row">
//                   <div className="col-12">
//                     <div className="table-content table-responsive cart-table-content">
//                       <table>
//                         <thead>
//                           <tr>
//                             <th>Product Name</th>
//                             <th>Unit Price</th>
//                             <th>Advance Payment Proof</th>
//                             <th>Qty</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//   {orders.map((order) => (
//     <Fragment key={order._id}>
//       <tr>
//         <td colSpan="8">
//           <strong>Order ID: {order._id}</strong>
//           <p>Status: {orderStatusMap[order.orderStatus]}</p>
//           <p>Total Price: {order.totalAmt}</p>
//           <p>Advance Amount: -{order.advAmt}</p>
//           <p>Grand Total Price: {order.totalAmt - order.advAmt}</p>
//           <p>USER NOTE: {order.note}</p>
//           {/* Additional order details can be shown here */}
//         </td>
//       </tr>
//       {order.items.map((item, key) => {
//         const advancePaymentPrice = order.advAmt; // Advance amount
//         const originalPrice = item.price;
//         const discountedPrice = item.disPer
//           ? originalPrice - (originalPrice * item.disPer / 100)
//           : originalPrice;

//         const finalProductPrice = discountedPrice || originalPrice;
//         const finalSubtotal = finalProductPrice * item.qty; // Total for this item

//         return (
//           <tr key={key}>
//             <td className="product-name">
//               <Link to={`${process.env.PUBLIC_URL}/product/${item._id}`}>
//                 {item.name}
//               </Link>
//             </td>
//             <td className="product-price-cart">
//               <span className="amount">₹{finalProductPrice}</span>
//             </td>
//             <td className="product-thumbnail">
//               <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleImageClick(order.imageUrl)}>
//                 View
//               </span>
//             </td>
//             <td className="product-quantity">
//               <span className="amount">{item.qty}</span>
//             </td>
//           </tr>
//         );
//       })}
//       <p>USER NOTE: {order.note}</p>
//     </Fragment>
//   ))}
// </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
                // <div className="row">
                //   <div className="col-lg-12">
                //     <div className="cart-shiping-update-wrapper">
                //       <div className="cart-shiping-update">
                //         <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>Continue Shopping</Link>
                //       </div>
                //     </div>
                //   </div>
                // </div>
//               </Fragment>
//             ) : (
//               <div className="row">
//                 <div className="col-lg-12">
//                   <div className="item-empty-area text-center">
//                     <div className="item-empty-area__icon mb-30">
//                       <i className="pe-7s-cart"></i>
//                     </div>
//                     <div className="item-empty-area__text">
//                       No items found in orders<br />
//                       <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>Shop Now</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Modal for Image Display */}
//         <Modal 
//           visible={isModalVisible} 
//           onCancel={handleModalClose} 
//           onOk={handleModalClose}
//           width={800}
//         >
//           <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
//         </Modal>
//       </LayoutOne>
//     </Fragment>
//   );
// };

// export default CheckOrders;





// import { Fragment, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import SEO from "../../components/seo";
// import { getDiscountPrice } from "../../helpers/product";
// import LayoutOne from "../../layouts/LayoutOne";
// import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import { getOrders } from "../../store/actions/order";

// const orderStatusMap = {
//   0: 'PENDING',
//   1: 'IN-PROCESS',
//   2: 'CANCEL',
//   3: 'COMPLETE',
// };

// const CheckOrders = () => {
//   const [cartTotalPrice, setCartTotalPrice] = useState(0);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   let { pathname } = useLocation();
//   const isLoggedIn = useSelector((state) => state.user.user !== null);
//   const orders = useSelector((state) => state.order.list);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       if (!isLoggedIn) {
//         navigate("/login-register"); // Redirect to login if not logged in
//         return;
//       }
//       await dispatch(getOrders());
//     };
//     fetchOrders();
//   }, [dispatch, isLoggedIn, navigate]);

//   return (
//     <Fragment>
//       <SEO titleTemplate="CheckOrders" description="Check Orders of 09Mobile Gallery." />
//       <LayoutOne headerTop="visible">
//         <Breadcrumb
//           pages={[
//             { label: "Home", path: process.env.PUBLIC_URL + "/" },
//             { label: "Orders", path: process.env.PUBLIC_URL + pathname },
//           ]}
//         />
//         <div className="cart-main-area pt-90 pb-100">
//           <div className="container">
//             {orders && orders.length > 0 ? (
//               <Fragment>
//                 <h3 className="cart-page-title">Your ordered items</h3>
//                 <div className="row">
//                   <div className="col-12">
//                     <div className="table-content table-responsive cart-table-content">
//                       <table>
//                         <thead>
//                           <tr>
//                             <th>Product Image</th>
//                             <th>Product Name</th>
//                             <th>Unit Price</th>
//                             <th>Advance Payment Price</th>
//                             <th>Advance Payment Proof</th>
//                             <th>Note</th>
//                             <th>Order Status</th>
//                             <th>Qty</th>
//                             <th>Total Payable</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {orders.map((order) =>
//                             order.items.map((item, key) => {
//                               const advancePaymentPrice = order.advAmt;
//                               // const discountedPrice = getDiscountPrice(item.price, item.discount || 0);
//                               const originalPrice = item.price;
//                               const discountedPrice = item.disPer
//                                 ? originalPrice - (originalPrice * item.disPer / 100)
//                                 : originalPrice;
//                               const finalProductPrice = item.price;
//                               const finalSubtotal = (discountedPrice || finalProductPrice) * item.qty;

//                               return (
//                                 <tr key={key}>
//                                   <td className="product-thumbnail">
//                                     <Link to={`${process.env.PUBLIC_URL}/product/${item._id}`}>
//                                       <img className="img-fluid" src={item.images[0]?.src} alt={item.name} />
//                                     </Link>
//                                   </td>
//                                   <td className="product-name">
//                                     <Link to={`${process.env.PUBLIC_URL}/product/${item._id}`}>
//                                       {item.name}
//                                     </Link>
//                                   </td>
//                                   <td className="product-price-cart">
//                                     {/* <span className="amount">₹{finalProductPrice}</span> */}
//                                     {discountedPrice && <span className="amount">₹{discountedPrice}</span>}
//                                   </td>
//                                   <td className="product-subtotal">₹{advancePaymentPrice}</td>
//                                   <td className="product-thumbnail">
//                                       <img className="img-fluid" src={order.imageUrl} alt={item.name} />
//                                   </td>
//                                   <td className="product-status">
//                                     <span className="amount">{orderStatusMap[order.orderStatus]}</span>
//                                   </td>
//                                   <td className="product-status">
//                                     <span className="amount">{order.note}</span>
//                                   </td>
//                                   <td className="product-quantity">
//                                     <span className="amount">{item.qty}</span>
//                                   </td>
//                                   <td className="product-subtotal">₹{finalSubtotal - advancePaymentPrice}</td>
//                                 </tr>
//                               );
//                             })
//                           )}
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-lg-12">
//                     <div className="cart-shiping-update-wrapper">
//                       <div className="cart-shiping-update">
//                         <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>Continue Shopping</Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </Fragment>
//             ) : (
//               <div className="row">
//                 <div className="col-lg-12">
//                   <div className="item-empty-area text-center">
//                     <div className="item-empty-area__icon mb-30">
//                       <i className="pe-7s-cart"></i>
//                     </div>
//                     <div className="item-empty-area__text">
//                       No items found in orders<br />
//                       <Link to={`${process.env.PUBLIC_URL}/shop-grid-standard`}>Shop Now</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </LayoutOne>
//     </Fragment>
//   );
// };

// export default CheckOrders;