import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDiscountPrice } from "../../../helpers/product";
import { deleteFromCart } from "../../../store/slices/cart-slice";
import { deleteCart, getCartProducts } from "../../../store/actions/cart-actions";

const MenuCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems || []);
  const isLoggedIn = useSelector((state) => state.user.user !== null);
  
  let cartTotalPrice = 0;

  const handleCheckout = async () => {
    if (!isLoggedIn) {
      navigate("/login-register");
      return;
    }
    navigate(process.env.PUBLIC_URL + "/checkout");
    await dispatch(getCartProducts());
  };

  const handleViewCart = async () => {
    if (!isLoggedIn) {
      navigate("/login-register");
      return;
    }
    await dispatch(getCartProducts());
    navigate(process.env.PUBLIC_URL + "/cart");
  };

  const handleDeleteFromCart = async (item) => {    
    await dispatch(deleteFromCart(item.cartItemId));
    await dispatch(deleteCart(item.cartId));
    await dispatch(getCartProducts());
  };

  // Calculate the total price
  cartItems.forEach(item => {
    const discountedPrice = getDiscountPrice(item.price, item.disPer || 0);
    const finalPrice = discountedPrice !== null ? discountedPrice : item.price;
    cartTotalPrice += finalPrice * item.qty;
  });

  return (
    <div className="shopping-cart-content">
      {cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              const discountedPrice = getDiscountPrice(item.price, item.disPer || 0);
              const finalProductPrice = item.price;

              return (
                <li className="single-shopping-cart" key={item.cartId}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/product/" + item.cartId}>
                      <img
                        alt=""
                        src={item.images[0]?.src || "/assets/img/noimgavl.png"}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={process.env.PUBLIC_URL + "/product/" + item.cartId}>
                        {item.name}
                      </Link>
                    </h4>
                    <h6>Qty: {item.qty}</h6>
                    <span>
                      ₹{discountedPrice !== null ? discountedPrice : finalProductPrice}
                    </span>
                    {item.selectedProductColor && item.selectedProductSize && (
                      <div className="cart-item-variation">
                        <span>Color: {item.selectedProductColor}</span>
                        <span>Size: {item.selectedProductSize}</span>
                      </div>
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => handleDeleteFromCart(item)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total:{" "}
              <span className="shop-total">₹{cartTotalPrice.toFixed(2)}</span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <button className="default-btn" onClick={handleViewCart}>
              View Bag
            </button>
            <button className="default-btn" onClick={handleCheckout}>
              Checkout
            </button>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to bag</p>
      )}
    </div>
  );
};

export default MenuCart;